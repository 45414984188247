<template>
    <div class="hictemplate">
        <section class="body1200" style="margin-top: 90px;">
            <div class="txt60" style="margin-top: 30px">HIC智慧照明</div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                辉腾能源“HIC智慧城市照明管理方案”基于城市道路照明为基础应用，建设共享资源平台，主要提供电力资源和网络资源云服务。针对特定的市政道路路灯场景，增加充电桩、多媒体视频、视频监控、环境采集、光伏发电等模块。

            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                理念与架构
            </div>
            <div class="hic_fa" style="margin-top: 60px;">
            </div>
        </section>

        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                方案展示
            </div>
            <div class="txt18" style="margin-top: 30px;width:860px;">

                智慧城市道路照明系统按照平台模式建设，可接入其他公司建设的市政道路照明（加入相关连接设备），按照独立项目模式进行管理。每个项目以集中管理器作为电力和网络的控制中心，控制着网络信息上传下达和电力系统输入与输出。
            </div>

            <div class="htable" style="margin-top: 30px;justify-content: center;width: 860px;border-bottom: 1px solid #e7e7e7;">
                <div class="hcell">
                    <span v-if="hicgn==1" class="txtH16 btLine">智慧光伏路灯</span>
                    <span v-else class="txtH16 ghblockclick" @click="hicgn=1">智慧光伏路灯</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hicgn==2" class="txtH16 btLine">智慧城管</span>
                    <span v-else class="txtH16 ghblockclick" @click="hicgn=2">智慧城管</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hicgn==3" class="txtH16 btLine">智慧交通</span>
                    <span v-else class="txtH16 ghblockclick" @click="hicgn=3">智慧交通</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hicgn==4" class="txtH16 btLine">智慧安防</span>
                    <span v-else class="txtH16 ghblockclick" @click="hicgn=4">智慧安防</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hicgn==5" class="txtH16 btLine">智慧安监</span>
                    <span v-else class="txtH16 ghblockclick" @click="hicgn=5">智慧安监</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hicgn==6" class="txtH16 btLine">智慧环保</span>
                    <span v-else class="txtH16 ghblockclick" @click="hicgn=6">智慧环保</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hicgn==7" class="txtH16 btLine">智慧运管</span>
                    <span v-else class="txtH16 ghblockclick" @click="hicgn=7">智慧运管</span>
                </div>

            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="hicgn==1">
            <div  class="txt42">
                智慧光伏路灯
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                路灯用市电，连接道路中心控制器，组成子网。带有太阳能板和逆变器的路灯，白天利用太阳能发电把电并入市电网。同时结合智能控制和分析系统实现智慧光伏路灯。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="block3 hic_gl11"> </div></div>
                <div class="hcell"><div class="block3 hic_gl12"> </div></div>
                <div class="hcell"><div class="block3 hic_gl13"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl14">
                    </div>
                    <div class="hicgl_tddh">光伏发电</div>
                    <div class="hicgl_tddt">以太阳能光电转换提供电能，充分利用清洁能源、也可接入市电。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl15">
                    </div>
                    <div class="hicgl_tddh">路灯监控</div>
                    <div class="hicgl_tddt">安防监控、人脸识别、智能区域报警、车辆监控、停车收费、违章行驶等。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl16">
                    </div>
                    <div class="hicgl_tddh">电能质量分析</div>
                    <div class="hicgl_tddt">发电设备电压调控，主要包括可调逆变器运行状态输出电压范围调节，以适应电网参数，提高发电量。</div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl17">
                    </div>
                    <div class="hicgl_tddh">能耗分析</div>
                    <!--<div class="hicgl_tddt">故障报警信息：直观展示、推送方式设定。</div>-->
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                </div></div>
            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="hicgn==2">
            <div  class="txt42">
                智慧城管
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
            对城市管理的道路、桥涵、排水、照明灯、危险源等业务建立分类数据信息库，实现维护工作信息化。从在线监测监控信息、现场维护记录、维护后设备现场验收和后期维护数据查询等一系列的信息化数据处理，以完善维护管理与维护信息查询系统。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="block3 hic_gl21"> </div></div>
                <div class="hcell"><div class="block3 hic_gl22"> </div></div>
                <div class="hcell"><div class="block3 hic_gl23"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl24">
                    </div>
                    <div class="hicgl_tddh">危险源监控</div>
                    <!--<div class="hicgl_tddt">电站各节点设备的运行信息：采集、分析、告警、显示。</div>-->
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl25">
                    </div>
                    <div class="hicgl_tddh">窨井盖状态</div>
                    <!--<div class="hicgl_tddt">电子地图显示：区域发电站监控终端各节点运行状态信息。</div>-->
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl26">
                    </div>
                    <div class="hicgl_tddh">桥梁监测</div>
                   <!-- <div class="hicgl_tddt">故障报警信息：直观展示、推送方式设定。</div>-->
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl27">
                    </div>
                    <div class="hicgl_tddh">隧道监测</div>
                    <!--<div class="hicgl_tddt">发电设备电压调控，主要包括可调逆变器运行状态输出电压范围调节，以适应电网参数，提高发电量。</div>-->
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl28">
                    </div>
                    <div class="hicgl_tddh">边坡治理</div>
                    <!--<div class="hicgl_tddt">根据发电站功率输出变化曲线，分析电站周围遮挡物处理建议，对输出功率进行环比，提供组件清洁建议。</div>-->
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl29">
                    </div>
                    <div class="hicgl_tddh">液位监测</div>
                    <!--<div class="hicgl_tddt">定义、管理及维护管理区域内的设备信息。</div>-->
                </div></div>
            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="hicgn==3">
            <div  class="txt42">
                智慧交通
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                随着城市车流量的不断增加，道路拥堵、停车难、充电不便等交通状况需要持续改善，通过智慧交通板块的功能，可以监测到实时的车位信息、道路信息、充电位信息等，再做出正确的指引，有效缓解城市拥堵问题。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="block3 hic_gl31"> </div></div>
                <div class="hcell"><div class="block3 hic_gl32"> </div></div>
                <div class="hcell"><div class="block3 hic_gl33"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl34">
                    </div>
                    <div class="hicgl_tddh">车位监控</div>
                    <!--<div class="hicgl_tddt">以太阳能光电转换提供电能，充分利用清洁能源、也可接入市电。</div>-->
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl35">
                    </div>
                    <div class="hicgl_tddh">停车引导</div>
                    <!--<div class="hicgl_tddt">安防监控、人脸识别、智能区域报警、车辆监控、停车收费、违章行驶等。</div>-->
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl36">
                    </div>
                    <div class="hicgl_tddh">智能导车</div>
                    <!--<div class="hicgl_tddt">发电设备电压调控，主要包括可调逆变器运行状态输出电压范围调节，以适应电网参数，提高发电量。</div>-->
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hic_gl37">
                    </div>
                    <div class="hicgl_tddh">停车充电</div>
                    <!--<div class="hicgl_tddt">故障报警信息：直观展示、推送方式设定。</div>-->
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hicgl_tdd">
                </div></div>
            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="hicgn==4">
            <div  class="txt42">
                智慧安防
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                智慧安防技术随着科学技术的发展与进步二十一世纪信息技术的腾飞已迈入了一个全新的领域，没有智慧安防技术社会就会显得不安宁，世界科学技术的前进和发展就会受之影响。智慧安防技术的测量必须依据国家安全标准和相关规范的方法正确执行，并以相关的规定标准极限参数作为依据。

            </div>
            <imgblock_three :hrow="imgblock_three4"  style="margin-top: 60px; " />
            <gnmrow :brow="gnmrow41"  style="margin-top: 60px;"> </gnmrow>
        </section>
        <section class="body1200 farm_ghblock" v-if="hicgn==5">
            <div  class="txt42">
                智慧安监
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                智慧安监核心理念是安全管理智慧化。即通过建立的各种数据模型与传感器采集的计算数据，广泛使用计算机、光纤、无线通讯、遥感、传感、红外、微波、监控等科学先进技术设备管理安全。实现对涉安人员不安全行为和事物不安全状态，迅速、灵活、正确地理解（预测）和解决（启动安全设备或报警）。
            </div>
            <imgblock_three :hrow="imgblock_three5"  style="margin-top: 60px; " />
            <gnmrow :brow="gnmrow51"  style="margin-top: 60px;"> </gnmrow>
        </section>
        <section class="body1200 farm_ghblock" v-if="hicgn==6">
            <div  class="txt42">
                智慧环保
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">

                智慧环保是数字环保概念的延伸和拓展，它是借助物联网技术，把感应器和装备嵌入到各种环境监控对象（物体）中，通过超级计算机和云计算将环保领域物联网整合起来，可以实现人类社会与环境业务系统的整合，以更加精细和动态的方式实现环境管理和决策的智慧。

            </div>
            <imgblock_three :hrow="imgblock_three6"  style="margin-top: 60px; " />
            <gnmrow :brow="gnmrow61"  style="margin-top: 60px;"> </gnmrow>
            <gnmrow :brow="gnmrow62"  style="margin-top: 60px;"> </gnmrow>
        </section>
        <section class="body1200 farm_ghblock" v-if="hicgn==7">
            <div  class="txt42">
                智慧运管
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                智慧运营是指通过智慧路灯平台的基础，植入 LED 显示屏、扩播系统、充电桩等盈利系统，通过上诉系统实现可持续的运营收益。
            </div>
            <imgblock_three :hrow="imgblock_three7"  style="margin-top: 60px; " />
            <gnmrow :brow="gnmrow71"  style="margin-top: 60px;"> </gnmrow>
        </section>

        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                智慧云平台
            </div>
            <div class="txt18" style="margin-top: 30px;width:860px;">

                智慧城市道路照明系统按照平台模式建设，可接入其他公司建设的市政道路照明（加入相关连接设备），按照独立项目模式进行管理。每个项目以集中管理器作为电力和网络的控制中心，控制着网络信息上传下达和电力系统输入与输出。
            </div>
            <div :style="'margin-top: 60px;height: 675px;background-image: url('+ hic_dp +');'"   >
            </div>
        </section>


        <section class="body1200" style="margin-top: 160px;">
            <div style="font-size: 42px;">
                方案优势
            </div>
            <div class="txtzw" style="width: 860px;margin-top: 60px;">
                辉腾光伏能源通过将信息技术、互联网技术与光伏技术跨界融合，集成高效光伏发电，高压储能，实现可视化家庭能源管理，场景化、简单化一键用电，为客户带来更安全、多发电、好管理的美好生活体验。
            </div>
            <div style="margin-top: 56px;">
                <img :src="hic_ys">
            </div>
        </section>

        <section class="body1200" style="margin-top: 160px;">
            <div style="font-size: 42px;">
                相关案例
            </div>
            <al_three :hrow="alrows" />
        </section>



    </div>

</template>
<script>
    import imgblock_three from "./control/imgblock_three.vue"
    import gnmrow from "./control/gn_m_row.vue"
    import al_three from "./control/al_three.vue"
    export default {
        data () {
            return {
                hicgn: 1,
                hic_gl14: require('../assets/hic_gl14.png'),
                hic_gl15: require('../assets/hic_gl15.png'),
                hic_gl16: require('../assets/hic_gl16.png'),
                hic_gl17: require('../assets/hic_gl17.png'),

                hic_gl24: require('../assets/hic_gl24.png'),
                hic_gl25: require('../assets/hic_gl25.png'),
                hic_gl26: require('../assets/hic_gl26.png'),
                hic_gl27: require('../assets/hic_gl27.png'),
                hic_gl28: require('../assets/hic_gl28.png'),
                hic_gl29: require('../assets/hic_gl29.png'),

                hic_gl34: require('../assets/hic_gl34.png'),
                hic_gl35: require('../assets/hic_gl35.png'),
                hic_gl36: require('../assets/hic_gl36.png'),
                hic_gl37: require('../assets/hic_gl37.png'),

                hic_gl44: require('../assets/hic_gl44.png'),
                hic_gl45: require('../assets/hic_gl45.png'),
                hic_gl46: require('../assets/hic_gl46.png'),



                hic_gl64: require('../assets/hic_gl64.png'),
                hic_gl65: require('../assets/hic_gl65.png'),
                hic_gl66: require('../assets/hic_gl66.png'),
                hic_gl67: require('../assets/hic_gl67.png'),
                hic_gl68: require('../assets/hic_gl68.png'),
                hic_gl69: require('../assets/hic_gl69.png'),

                hic_gl74: require('../assets/hic_gl54.png'),
                hic_gl75: require('../assets/hic_gl55.png'),
                hic_gl76: require('../assets/hic_gl56.png'),
                hic_ys: require('../assets/hic_ys.png'),

                imgblock_three4:[
                    {tb_image:require('../assets/hic_gl41.jpg')},
                    {tb_image:require('../assets/hic_gl42.jpg')},
                    {tb_image:require('../assets/hic_gl43.jpg')},
                ],
                gnmrow41:[
                    {tb_image:require('../assets/hic_gl44.png'),tb_title:'视频监控',tb_summary:''},
                    {tb_image:require('../assets/hic_gl45.png'),tb_title:'一键求救',tb_summary:''},
                    {tb_image:require('../assets/hic_gl46.png'),tb_title:'定位系统',tb_summary:''},
                ],

                imgblock_three5:[
                    {tb_image:require('../assets/hic_gl51.jpg')},
                    {tb_image:require('../assets/hic_gl52.jpg')},
                    {tb_image:require('../assets/hic_gl53.jpg')},
                ],
                gnmrow51:[
                    {tb_image:require('../assets/hic_gl54.png'),tb_title:'应急抢险',tb_summary:''},
                    {tb_image:require('../assets/hic_gl55.png'),tb_title:'隐患排查',tb_summary:''},
                    {tb_image:require('../assets/hic_gl56.png'),tb_title:'有毒有害气体监控',tb_summary:''},
                ],

                imgblock_three6:[
                    {tb_image:require('../assets/hic_gl61.jpg')},
                    {tb_image:require('../assets/hic_gl62.jpg')},
                    {tb_image:require('../assets/hic_gl63.jpg')},
                ],
                gnmrow61:[
                    {tb_image:require('../assets/hic_gl64.png'),tb_title:'扬尘监控',tb_summary:''},
                    {tb_image:require('../assets/hic_gl65.png'),tb_title:'温湿度监测',tb_summary:''},
                    {tb_image:require('../assets/hic_gl66.png'),tb_title:'空气质量监测',tb_summary:''},
                ],
                gnmrow62:[
                    {tb_image:require('../assets/hic_gl67.png'),tb_title:'噪音监控',tb_summary:''},
                    {tb_image:require('../assets/hic_gl68.png'),tb_title:'光污染监控',tb_summary:''},
                    {tb_image:require('../assets/hic_gl69.png'),tb_title:'水质监测',tb_summary:''},
                ],

                imgblock_three7:[
                    {tb_image:require('../assets/hic_gl71.jpg')},
                    {tb_image:require('../assets/hic_gl72.jpg')},
                    {tb_image:require('../assets/hic_gl73.jpg')},
                ],
                gnmrow71:[
                    {tb_image:require('../assets/hic_gl74.png'),tb_title:'LED 显示屏',tb_summary:''},
                    {tb_image:require('../assets/hic_gl75.png'),tb_title:'广播系统',tb_summary:''},
                    {tb_image:require('../assets/hic_gl76.png'),tb_title:'智能充电桩',tb_summary:''},
                ],
                hic_dp:require('../assets/hic_dp.jpg'),

                alrows:[
                    {tb_image:require('../assets/hec_al1.jpg'),tb_title:'四川阿坝1MW扶贫光伏项目'},
                    {tb_image:require('../assets/hec_al2.jpg'),tb_title:'四川理塘6MW扶贫光伏项目'},
                    {tb_image:require('../assets/hec_al3.jpg'),tb_title:'重庆巫溪整县扶贫光伏项目'},
                ],
                address: require('../assets/address.png'),

            }
        },
        components: {
            gnmrow,
            imgblock_three,
            al_three,
        },
        mounted() {
            this.$parent.hicbanner()
        },
        methods: {

        },
        watch: {

        },
    }
</script>
<style lang="css" scoped>
    .hictemplate{
        color: #202020;
        text-align: left;
    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }

    .farm_ghblock{
        margin-top: 60px;
        /*height: 940px;*/
        /*border: 1px solid red;*/
    }
    .txtH16{
        font-size: 16px;
        color:rgba(20,20,20,0.8);
        line-height: 80px;
    }
    .hic_fa{
        background-image: url('~assets/hic_jg.jpg');
        height: 614px;
    }
    .ghblockclick{
        cursor:pointer;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 28px;
    }

    .block3{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }
    .hic_gl11{background-image: url('~assets/hic_gl11.jpg');}
    .hic_gl12{background-image: url('~assets/hic_gl12.jpg');}
    .hic_gl13{background-image: url('~assets/hic_gl13.jpg');}
    .hic_gl21{background-image: url('~assets/hic_gl21.jpg');}
    .hic_gl22{background-image: url('~assets/hic_gl22.jpg');}
    .hic_gl23{background-image: url('~assets/hic_gl23.jpg');}
    .hic_gl31{background-image: url('~assets/hic_gl31.jpg');}
    .hic_gl32{background-image: url('~assets/hic_gl32.jpg');}
    .hic_gl33{background-image: url('~assets/hic_gl33.jpg');}



    .hicgl_tdd{
        width: 320px;

        /*border: 1px solid red;*/
    }
    .hicgl_tddh{
        font-size: 18px;
        font-weight: bold;
        color: rgba(20,20,20,0.9);
        margin-top: 30px;
    }
    .hicgl_tddt{
        font-size: 16px;
        color: rgba(20,20,20,0.8);
        margin-top: 20px;
        line-height: 20px;
    }

</style>
